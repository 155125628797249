import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Button,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";

export default function PreProtectedSuperAdmin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async () => {
    try {
      // Call the login API
      const loginResponse = await axios.post("https://layapriya.bringecommerce.site/api/layapriya/login", {
        emailOrPhone: email,
        password,
      });
      console.log(loginResponse.data);
      if (loginResponse.data.status=='success' && loginResponse.data.token && loginResponse.data.user.role=='super-admin') {
        const superAdminResponse = await axios.get("https://layapriya.bringecommerce.site/api/layapriya/superadmin-route", {
          headers: {
            Authorization: `Bearer ${loginResponse.data.token}`, // Assuming the token is returned in the login response
          },
        });

        if (superAdminResponse.data.role=='super-admin') {
          // If super admin check is successful, navigate to the super admin page
          navigate("/super-admin-conquered");
        } else {
          alert("You do not have super admin permissions.");
        }
      } else {
        alert("Login failed.");
      }
    } catch (error) {
      console.error("Error during login or super admin check:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <Container style={{ textAlign: "center", marginTop: "20%" }}>
      <Box>
        <TextField
          fullWidth
          label="Email"
          variant="standard"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ marginBottom: "16px" }}
        />
        <TextField
          fullWidth
          label="Password"
          variant="standard"
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={togglePasswordVisibility} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          style={{ marginBottom: "16px" }}
        />
        <Button variant="contained" color="primary" onClick={handleLogin}>
          Login
        </Button>
      </Box>
    </Container>
  );
}
