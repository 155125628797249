import React from 'react';
import './InstrumentSection.css';

const instruments = [
  { name: 'Mridangam', image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRY4Cvdh6BrnRVQfo_jwmi7SGWT2NEPsaI9rw&s' },
  { name: 'Flute', image: 'https://images.unsplash.com/photo-1698912236929-fd2131fa4e02?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGZsdXRlfGVufDB8fDB8fHww' },
  { name: 'Kartal', image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQm1Ff-0mmrrwyZhDyBIGXZx8xKScltmEjBR_gJQDSyCku826r8pNUaOsPX6OCvzjLnCg8&usqp=CAU' }
];


const InstrumentSection = () => {
  return (
    <section className="instruments">
      {instruments.map((instrument, index) => (
        <div key={index} className="instrument-card">
          <img src={instrument.image} alt={instrument.name} />
          <h3>{instrument.name}</h3>
        </div>
      ))}
    </section>
  );
};

export default InstrumentSection;
