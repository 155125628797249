import React, { createContext, useState, useEffect } from 'react';
import { validateToken } from '../services/auth';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authState, setAuthState] = useState({ user: null, isAuthenticated: false });

    // useEffect(() => {
    //     const checkAuth = async () => {
    //         try {
    //             const { data } = await validateToken();
    //             if (data.status === "success") {
    //                 setAuthState({ user: data.user, isAuthenticated: true });
    //             }
    //         } catch (err) {
    //             console.error("Token validation failed", err);
    //         }
    //     };
    //     checkAuth();
    // }, []);

    return (
        <AuthContext.Provider value={{ authState, setAuthState }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
