import React from "react";
import "./FocusOnKarnatik.css";

const FocusOnKarnatik = () => {
  return (
    <section className="focus-section">
      <div className="focus-content">
        <h2>Explore Karnatik Music</h2>
        <p>
          Karnatik music, originating from South India, is one of the world's
          oldest musical traditions. Known for its rhythmic complexity and rich
          melodic structures, Karnatik music allows students to delve deep into
          the nuances of sound and rhythm.
        </p>
        <p>
          Whether you're learning Mridangam, Flute, or Kartal, this tradition
          offers a profound understanding of music, culture, and devotion.
        </p>
      </div>

      <img
          src="https://media.gettyimages.com/id/935007832/photo/music-students-from-the-veenalayam-temple-of-music-perform-with-world-renowned-veena-artist.jpg?s=612x612&w=gi&k=20&c=RUQDDis4mFVAtJyrYcC9tu_5PkIIPdOGi1vZ8ZxP8Pg="
          alt="Karnatik Music"
        className="about-image"
      />
    </section>
  );
};

export default FocusOnKarnatik;


