import React, { useEffect } from "react";
import Footer from "./Footer/Footer";
import HeroSection from "./heroSection/HeroSection";
import InstrumentSection from "./InstrumentSection/InstrumentSection";
import RegistrationCTA from "./RegistrationCTA/RegistrationCTA";
import AboutLayapriya from "./About/AboutLayapriya";
import FocusOnKarnatik from "./FocusOnKarnatik/FocusOnKarnatik";
import Header from "./header/Header";
import { useNavigate } from "react-router-dom";

export default function HomePage(){
    const navigate = useNavigate();
    useEffect(() => {
        navigate("/register")
    },[])
    return(
        <>
            {/* <Header/> */}
            <HeroSection/>
            <InstrumentSection/>
            <RegistrationCTA/>
            <AboutLayapriya/>
            <FocusOnKarnatik/>
            <Footer/>
        </>
    )
}