import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Typography, Container, Box, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom"; 
import "./style.css";

export default function SuccessStripe() {
  const [studentData, setStudentData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const sessionId = window.location.href.split('/')[5];
    const userId = window.location.href.split('/')[6];

    if (userId && sessionId) {
      axios
        .get(`https://layapriya.bringecommerce.site/api/payments/stripe-success/${sessionId}/${userId}`)
        // .get(`http://localhost:3002/api/payments/stripe-success/${sessionId}/${userId}`)

        .then((response) => {
          if (response.data.statusCode === 404) {
            navigate("/page-not-found"); // Redirect to the page not found if 404
          } else {
            setStudentData(response.data.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching payment status:", error);
        });
    } else {
      console.error("No userId or sessionId found in the query parameters");
    }
  }, []);

  const handleLogin = () => {
    navigate("/login");
  };

  if (!studentData) {
    return (
      <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh', textAlign: 'center' }}>
        <p>
        <CircularProgress size={18}/>
        </p>
        <Typography variant="h6">Loading your details...</Typography>
      </Container>
    );
  }

  return (
    <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh', textAlign: 'center' }}>
      <Box sx={{ marginBottom: 3 }}>
      <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
          </svg>
      </Box>

      <Typography variant="h4" sx={{ marginBottom: 2 }}>
        Payment Successful!
      </Typography>

      <Typography variant="body1" sx={{ marginBottom: 2 }}>
        Thank you, {studentData.firstName} {studentData.lastName}, for completing your payment.
      </Typography>

      <Typography variant="body2" sx={{ marginBottom: 4 }}>
        We have successfully received your payment. You are now registered as a student from {studentData.city}, {studentData.state}, {studentData.country}. 
        {/* Please log in to explore more details about your classes.L */}
      </Typography>

      {/* <Button
        variant="contained"
        color="primary"
        onClick={handleLogin}
        sx={{ padding: '10px 20px' }}
      >
        Go to Login
      </Button> */}
    </Container>
  );
}
