import React from "react";
import SuperAdminAppbar from "./SuperAdminAppbar";
import { Outlet, useLocation } from 'react-router-dom';
import { Box, Container, Typography } from '@mui/material';
import SuperAdminDashboard from "./Dashboard/SuperAdminDashboard";

export default function SuperAdminHomePage() {
  const location = useLocation();

  function getGreetingInCanada() {
    // Define options to get the hour in the specific timezone (Canada/Eastern as an example)
    const options = {
      timeZone: 'America/Toronto', // Replace with the specific timezone for your region in Canada
      hour12: false, // Use 24-hour format
      hour: 'numeric',
    };
  
    // Get the current hour in the specified Canada timezone
    const currentHour = new Intl.DateTimeFormat('en-US', options).format(new Date());
  
    // Determine greeting based on hour
    if (currentHour >= 5 && currentHour < 12) {
      return 'Good Morning';
    } else if (currentHour >= 12 && currentHour < 18) {
      return 'Good Afternoon';
    } else {
      return 'Good Night';
    }
  }

  const renderComponent = () => {
    if (location.pathname === '/super-admin-conquered' || location.pathname === '/super-admin-conquered/') {
      return <SuperAdminDashboard apiUrl={'https://layapriya.bringecommerce.site/api/layapriya/get-upcoming-meetings/super-admin'}/>;
    }
    return <Outlet />;
  };
  
  return (
    <>
      <SuperAdminAppbar />
      <Container maxWidth="lg">
        <Box mt={4}>
          <Typography variant="h4" gutterBottom>
            {getGreetingInCanada()}
          </Typography>
         
         {renderComponent()}
        </Box>
      </Container>
    </>
  );
}
