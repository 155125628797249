import React from 'react';
import { Link } from 'react-router-dom';
import './RegistrationCTA.css';
// import musicImage from './music-image.jpg'; // Ensure you have this image in your project

const musicImage = 'https://images.unsplash.com/photo-1633411988188-6e63354a9019?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
const RegistrationCTA = () => {
  return (
    <section className="cta">
      <div className="cta-content">
        <h2>Ready to start your musical journey?</h2>
        <p>Join us at Layapriya School of Performing Arts and explore the world of Indian classical music.</p>
        <Link to="/register" className="cta-button">Register Now</Link>
      </div>
      <div className="cta-image">
        <img src={musicImage} alt="Music Journey" />
      </div>
    </section>
  );
};

export default RegistrationCTA;
