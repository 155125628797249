import React, { useState } from 'react';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  useMediaQuery,
  useTheme,
  Slide,
  Paper,
  Snackbar,
  Alert
} from '@mui/material';
import { useSpring, animated } from 'react-spring';
import axios from 'axios';
import LayoutComponent from './LayoutComponent';
import TestPayment from "../../TestPayments/TestPayment";
import { useNavigate } from 'react-router-dom';
import { isValidPhoneNumber } from 'libphonenumber-js';


const Register = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    mobileNumber: '',
    email: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
    password: '',
    confirmPassword: '',
    agreeToTerms: false,
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [submitted, setSubmitted] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackBar, setSnackBar] = useState('');
  const [severity, setSeverity] = useState('');

  const [insertionId, setInsertionId] = useState('');

  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });

    // Clear error messages on input change
    validateField(name, value);
  };

  const validateField = (name, value) => {
    const newErrors = { ...errors };

    switch (name) {
      case 'firstName':
        if (!value) newErrors.firstName = 'First Name is required';
        else delete newErrors.firstName;
        break;
      case 'lastName':
        if (!value) newErrors.lastName = 'Last Name is required';
        else delete newErrors.lastName;
        break;
      case 'mobileNumber':
        if (!value || !isValidPhoneNumber(value)) {
          newErrors.mobileNumber = 'Please enter a valid mobile number';
        } else {
          delete newErrors.mobileNumber;
        }
        break;
      case 'email':
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!value || !emailRegex.test(value)) {
          newErrors.email = 'Please enter a valid email address';
        } else {
          delete newErrors.email;
        }
        break;
      case 'city':
        if (!value) newErrors.city = 'City is required';
        else delete newErrors.city;
        break;
        case 'state':
          if (!value) newErrors.state = 'State/Province is required';
          else delete newErrors.state;
          break;
        
      case 'country':
        if (!value) newErrors.country = 'Country is required';
        else delete newErrors.country;
        break;
        case 'pincode':
          const pincodeRegex = /^[A-Za-z0-9\s\-]{3,10}$/;
          if (!value || !pincodeRegex.test(value)) {
            newErrors.pincode = 'Please enter a valid postal code';
          } else {
            delete newErrors.pincode;
          }
          break;
      case 'password':
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
        if (!value || !passwordRegex.test(value)) {
          newErrors.password = 'Enter strong password';
        } else {
          delete newErrors.password;
        }
        break;
      case 'confirmPassword':
        if (value !== formData.password) {
          newErrors.confirmPassword = 'Passwords do not match';
        } else {
          delete newErrors.confirmPassword;
        }
        break;
      case 'agreeToTerms':
        if (!value) {
          newErrors.agreeToTerms = 'You must agree to the terms and conditions';
        } else {
          delete newErrors.agreeToTerms;
        }
        break;
      default:
        break;
    }

    setErrors(newErrors);
  };

  const validateStep1 = async () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = 'First Name is required';
    if (!formData.lastName) newErrors.lastName = 'Last Name is required';
    if (!formData.mobileNumber || !isValidPhoneNumber(formData.mobileNumber)) {
      newErrors.mobileNumber = 'Please enter a valid mobile number';
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email || !emailRegex.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
    }
    setErrors(newErrors);
  
    if (Object.keys(newErrors).length === 0) {
      try {
        setLoading(true);
        
        // Call the API to check if the user is already registered
        const res = await axios.post('https://layapriya.bringecommerce.site/api/layapriya/search-phone-email', {
          phoneorEmail: formData.mobileNumber || formData.email,
        });
  
        if (res.data.statusCode === 202) {
          setOpenSnackbar(true);
          setSeverity('error');
          setSnackBar('User is already registered.');
          return false;
        } else if(res.data.statusCode==403) {
          setOpenSnackbar(false);
          setSeverity('error');
          setSnackBar('User is already registered.');

        }else {
          return true;
        }
      } catch (error) {
        console.error('Error validating user:', error);
        // setOpenSnackbar(true);
        setSeverity('error');
        setSnackBar('Error checking user registration. Please try again.');
        return false;
      } finally {
        setLoading(false);
      }
    } else {
      return false;
    }
  };
  
  const validateStep2 = () => {
    const newErrors = {};
    if (!formData.city) newErrors.city = 'City is required';
    if (!formData.state) newErrors.state = 'State is required';
    if (!formData.country) newErrors.country = 'Country is required';
    const pincodeRegex = /^[A-Za-z0-9\s\-]{3,10}$/;
    if (!formData.pincode || !pincodeRegex.test(formData.pincode)) {
      newErrors.pincode = 'Please enter a valid postal code';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };  


  const validateStep3 = () => {
    const newErrors = {};
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
    if (!formData.password || !passwordRegex.test(formData.password)) {
      newErrors.password = 'Password must meet complexity requirements';
    }
    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }
    if (!formData.agreeToTerms) {
      newErrors.agreeToTerms = 'You must agree to the terms and conditions';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNextStep = () => {
    if (step === 1 && validateStep1()) {
      setStep(2);
    } else if (step === 2 && validateStep2()) {
      setStep(3);
    } else if (step === 3 && validateStep3()) {
      handleSubmit();
      setStep(4);
    } else {
      setOpenSnackbar(true);
      setSeverity('error')
      setSnackBar('Please fill out all required fields correctly.');

    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      let res = await axios.post('https://layapriya.bringecommerce.site/api/layapriya/register', formData);
      if(res.data.status=='success'){
        setOpenSnackbar(true);
        setSeverity('success');
        setSnackBar(res.data.message);
        window.localStorage.setItem('userId',res.data._id);
        setInsertionId(res.data._id);
        setStep(4);
      }
      else if(res.data.statusCode==200){
        setOpenSnackbar(true);
        setSeverity('error');
        setSnackBar(res.data.message);
      }
      else if(res.data.message){
        setOpenSnackbar(true);
        setSeverity('error');
        setSnackBar(res.data.message)
      }
      

    } catch (error) {
      
      setOpenSnackbar(true);
      setSeverity('error');
      setSnackBar('Internal Server Error! Please try again later')
      console.error('There was an error registering the user:', error);
      
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const renderFormStep1 = () => (
    <>
      <Typography variant="h4" gutterBottom align="center" sx={{ mb: 3 }} style={{ fontWeight: "600" }}>
        Sign Up
      </Typography>
      <div
        style={{
          padding: "2px",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <p><b>Exciting News!</b></p>
        <p>Our website is launching on October 12th, 2024, PDT. Get ready to explore something amazing! Stay tuned for a world of inspiration, insights, and incredible experiences. We can't wait to share it with you!</p>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {renderTextField('First Name', 'firstName', formData.firstName, errors.firstName)}
        </Grid>
        <Grid item xs={12}>
          {renderTextField('Last Name', 'lastName', formData.lastName, errors.lastName)}
        </Grid>
        <Grid item xs={12}>
          {renderTextField('Mobile Number (Enter with Country code)', 'mobileNumber', formData.mobileNumber, errors.mobileNumber)}
        </Grid>
        <Grid item xs={12}>
          {renderTextField('Email', 'email', formData.email, errors.email)}
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={handleNextStep}
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              py: 1.5,
              fontWeight: 'bold',
              bgcolor: 'primary.main',
              '&:hover': {
                bgcolor: 'primary.dark',
              },
            }}
          >
            Proceed
          </Button>
        </Grid>
      </Grid>
    </>
  );

  const renderFormStep2 = () => (
      <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>

        {renderTextField('City', 'city', formData.city, errors.city)}
        </Grid>
        <Grid item xs={12} sm={6}>
        {renderTextField('State/Province', 'state', formData.state, errors.state)}
        </Grid>
        <Grid item xs={12} sm={6}>
        {renderTextField('Country', 'country', formData.country, errors.country)}
        </Grid>
        <Grid item xs={12} sm={6}>
        {renderTextField('Pincode', 'pincode', formData.pincode, errors.pincode)}
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={() => setStep(1)}
            variant="contained"
            color="secondary"
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Button
            onClick={handleNextStep}
            variant="contained"
            color="primary"
          >
            Proceed
          </Button>
        </Grid>
      </Grid>
      </>
  );

  const renderFormStep3 = () => (
    <>
    
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          
        {renderTextField('Password', 'password', formData.password, errors.password)}
        </Grid>
        <Grid item xs={12} sm={6}>
        {renderTextField('Confirm Password', 'confirmPassword', formData.confirmPassword, errors.confirmPassword)}

        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.agreeToTerms}
                onChange={handleChange}
                name="agreeToTerms"
              />
            }
            label="I agree to the terms and conditions"
          />
          {errors.agreeToTerms && (
            <Typography color="error" variant="caption">
              {errors.agreeToTerms}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={() => setStep(2)}
            variant="contained"
            color="secondary"
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </Grid>
      </Grid>
      </>
  );


  const renderTextField = (label, name, value, errorText, type = 'text') => (
    <div style={{ position: 'relative', marginBottom: '2rem' }}>
      <TextField
        label={label}
        name={name}
        value={value}
        onChange={handleChange}
        error={!!errorText}
        type={type}
        fullWidth
        required
      />
      {!!errorText && (
        <Typography
          variant="caption"
          color="error"
          style={{ position: 'absolute', top: '100%', left: '0', marginTop: '4px' }}
        >
          {errorText}
        </Typography>
      )}
    </div>
  );

  const fade = useSpring({ opacity: loading ? 0.5 : 1 });

  return (
    <LayoutComponent
      imageUrl="https://static.vecteezy.com/system/resources/previews/036/798/295/non_2x/ai-generated-wooden-guitar-pottery-bowl-maracas-a-celebration-of-cultures-generated-by-ai-free-photo.jpg"
      title=""
    >
      <Grid item style={{ width: '100%', maxWidth: '400px' }}>
        {loading && (
          <animated.div style={fade}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                p: 3,
                borderRadius: 2
              }}
            >
              <center>
                <CircularProgress />
              </center>
              <center>
                <Typography variant="h6" align="center">Please wait...</Typography>
              </center>
            </Box>
          </animated.div>
        )}
        {submitted ? (
          <Typography variant="h5" align="center" color="success.main">
            Registration successful!
          </Typography>
        ) : (
          <Slide direction="up" in={!loading}>
            <Paper sx={{ p: 1, borderRadius: 2 }}>
              {step === 1 && renderFormStep1()}
              {step === 2 && renderFormStep2()}
              {step === 3 && renderFormStep3()}
              {step === 4 && <TestPayment userId={insertionId} />}
            </Paper>
          </Slide>
        )}
      </Grid>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={severity}>
          {snackBar}
        </Alert>
      </Snackbar>
    </LayoutComponent>
  );
};

export default Register;