import React, { useEffect, useState } from 'react';
import './HeroSection.css';
import { Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const HeroSection = () => {
  const instruments = ["Mridangam", "Flute", "Kartal"];
  const [currentText, setCurrentText] = useState('');
  const [index, setIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [typingSpeed, setTypingSpeed] = useState(150); // Typing speed for each character
  const [waitBeforeDelete, setWaitBeforeDelete] = useState(false); // Control waiting before deleting

  const navigate = useNavigate();

  useEffect(() => {
    const typeWriter = () => {
      const fullText = `${instruments[index]}`;
      
      // Typing the text
      if (!isDeleting && currentText !== fullText) {
        setCurrentText(fullText.substring(0, currentText.length + 1));
      }
      // Pause for 1 second after the text is fully typed
      else if (!isDeleting && currentText === fullText && !waitBeforeDelete) {
        setWaitBeforeDelete(true);
        setTimeout(() => {
          setIsDeleting(true);
          setWaitBeforeDelete(false);
        }, 1000); // 1 second delay before deleting
      }
      // Deleting the text
      else if (isDeleting && currentText !== '') {
        setCurrentText(fullText.substring(0, currentText.length - 1));
      } 
      // Move to the next instrument and start typing again
      else if (isDeleting && currentText === '') {
        setIsDeleting(false);
        setIndex((prevIndex) => (prevIndex + 1) % instruments.length);
        setTypingSpeed(150); // Reset typing speed
      }
    };

    const timer = setTimeout(() => {
      typeWriter();
    }, typingSpeed);

    return () => clearTimeout(timer);
  }, [currentText, isDeleting, instruments, index, typingSpeed, waitBeforeDelete]);

  return (
    <section className="hero">
      <h1 className="animated-text">
        Transform sound into devotion, <br /> embrace the beauty of music through <br />
        {currentText}
        <span className="cursor">|</span>
      </h1>
      <div className="button-container" style={{margin:'1rem'}}>
        <Button type="dashed"style={{margin:'1rem'}}>More Info</Button>
        <Button className="register-button" type="primary" style={{margin:'1rem'}} icon={<RightOutlined />} onClick={() => navigate("/register")}>Register Now</Button>
      </div>
    </section>
  );
};

export default HeroSection;
